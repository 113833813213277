<template>
  <div class="app-introduce">

    <div class="app-text">
      <h3>应用名称</h3>
      <p>海菁</p>
      <h3>应用简介</h3>
      <p>用于企业员工、客户以及供应商处理相关业务，提供了业务对接、沟通等功能</p>
      <h3>使用前必读</h3>
      <p>1.苹果端跳转到 AppStore 后点击右上角 "兑换" 即可下载安装</p>
      <p>2.苹果端更新可在 AppStore-个人中心-已购项目 中更新版本</p>
      <p>3.电脑端打开此网页无效，请使用手机下载。</p>
      <h3>开发者</h3>
      <p>深圳市前海辰宇投资咨询有限公司</p>
<!--      <a-button @click="downloadClick" class="downloadButton" type="primary" shape="round" size="large">-->

<!--        <template #icon>-->
<!--          <DownloadOutlined class="icon" />-->
<!--          下载APP-->
<!--        </template>-->
<!--        <div/>-->
<!--      </a-button>-->
    </div>

  </div>
</template>

<script>

import { DownloadOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Introduce',
  components: {
    DownloadOutlined
  },
  methods: {
    downloadClick () {
      if (this.isWeiXin()) {
        alert('请点击右上角↗️使用浏览器打开')
        return
      }
      if (this.isAndroid()) {
        window.location.href = 'https://sunivers-erp.oss-cn-shenzhen.aliyuncs.com/prod/front/app/version/app-release.apk'
        alert('安装包开始下载')
      } else if (this.isIOS()) {
        fetch('https://erp.suniversgroup.com/newErp/apple/download/url')
            .then(data => {
              return data.text()
            }).then(result => {
          window.location.href = result
        })
      } else {
        alert('请使用手机下载安装')
      }
    },
    isAndroid () {
      const u = navigator.userAgent
      return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    },
    isIOS () {
      const u = navigator.userAgent
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    },

    isWeiXin () {
      const ua = window.navigator.userAgent.toLowerCase()
      const o = ua.match(/MicroMessenger/i)
      if (o && o.toString() === 'micromessenger') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>¡

<style scoped>
.app-introduce {
  width: 100%;
  height: 100%;
  padding: 1rem 0.5rem;
  text-align: center;
}
.app-logo {
  display: block;
  margin: 0 auto;
  line-height: 1rem;
}
.app-text{
  margin-top: 1rem;
  float: left;
  text-align: left;
}

.downloadButton{
  display: flex;
  margin-top: 1rem;
  margin-left: 15%;
  width: 70%;
  justify-content: center;
  margin-bottom: 26px
}

.icon{
  margin-top: 0.08rem;
  margin-right: 0.2rem;
}

</style>
